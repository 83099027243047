.ecovest-onboarding {
  background-color: #ffffff;
  height: 6171px;
  position: relative;
  width: 1440px;
}

.ecovest-onboarding .overlap-wrapper {
  background-color: var(--ecogreen-900);
  height: 3766px;
  left: 0;
  position: absolute;
  top: 811px;
  width: 1440px;
}

.ecovest-onboarding .overlap {
  height: 3696px;
  position: relative;
  top: 70px;
}

.ecovest-onboarding .overlap-2 {
  height: 3696px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.ecovest-onboarding .rectangle {
  background: linear-gradient(180deg, rgb(158, 207, 54) 0%, rgb(255, 255, 255) 100%);
  height: 1782px;
  position: absolute;
  top: 1039px;
  width: 1439px;
}

.ecovest-onboarding .rectangle-2 {
  background-color: var(--basewhite);
  height: 875px;
  position: absolute;
  top: 2821px;
  width: 1439px;
}

.ecovest-onboarding .group {
  height: 816px;
  left: 312px;
  position: absolute;
  top: 825px;
  width: 816px;
}

.ecovest-onboarding .overlap-group-2 {
  height: 816px;
  position: relative;
}

.ecovest-onboarding .ellipse-5 {
  height: 194px;
  left: 311px;
  position: absolute;
  top: 311px;
  width: 194px;
}

.ecovest-onboarding .ellipse-6 {
  height: 252px;
  left: 282px;
  position: absolute;
  top: 282px;
  width: 252px;
}

.ecovest-onboarding .ellipse-7 {
  height: 314px;
  left: 251px;
  position: absolute;
  top: 251px;
  width: 314px;
}

.ecovest-onboarding .ellipse-8 {
  height: 390px;
  left: 213px;
  position: absolute;
  top: 213px;
  width: 390px;
}

.ecovest-onboarding .ellipse-9 {
  height: 480px;
  left: 168px;
  position: absolute;
  top: 168px;
  width: 480px;
}

.ecovest-onboarding .ellipse-10 {
  height: 582px;
  left: 117px;
  position: absolute;
  top: 117px;
  width: 582px;
}

.ecovest-onboarding .ellipse-11 {
  height: 696px;
  left: 60px;
  position: absolute;
  top: 60px;
  width: 696px;
}

.ecovest-onboarding .ellipse-12 {
  height: 816px;
  left: 0;
  position: absolute;
  top: 0;
  width: 816px;
}

.ecovest-onboarding .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 85px;
  position: absolute;
  top: 0;
}

.ecovest-onboarding .playful-engaging {
  color: #9ecf36;
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 220px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 176px;
  margin-top: -1px;
  position: relative;
  width: 918px;
}

.ecovest-onboarding .text-wrapper {
  color: #ffffff;
  font-family: "Kufam", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 953px;
}

.ecovest-onboarding .img-2 {
  height: 812px;
  left: 0;
  position: absolute;
  top: 613px;
  width: 1440px;
}

.ecovest-onboarding .image {
  height: 717px;
  left: 444px;
  position: absolute;
  top: 105px;
  width: 996px;
}

.ecovest-onboarding .image-2 {
  height: 701px;
  left: 865px;
  position: absolute;
  top: 1175px;
  width: 575px;
}

.ecovest-onboarding .green-beautiful-tree {
  height: 1364px;
  left: 600px;
  position: absolute;
  top: 2332px;
  width: 840px;
}

.ecovest-onboarding .rectangle-3 {
  background: linear-gradient(180deg, rgb(158, 207, 54) 0%, rgba(255, 255, 255, 0) 100%);
  height: 187px;
  position: absolute;
  top: 3509px;
  transform: rotate(-180deg);
  width: 1439px;
}

.ecovest-onboarding .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 85px;
  position: absolute;
  top: 2415px;
}

.ecovest-onboarding .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
}

.ecovest-onboarding .container-wrapper {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--graymain-20);
  border-radius: 96px;
  height: 161px;
  overflow: hidden;
  position: relative;
  width: 375px;
}

.ecovest-onboarding .container {
  align-items: center;
  display: inline-flex;
  gap: 32px;
  justify-content: center;
  left: 25px;
  position: relative;
  top: 24px;
}

.ecovest-onboarding .green-coin {
  height: 110px;
  position: relative;
  width: 110px;
}

.ecovest-onboarding .balance {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  padding: 8px 0px;
  position: relative;
}

.ecovest-onboarding .text-wrapper-2 {
  color: var(--graymain-200);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .frame-5 {
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.ecovest-onboarding .image-3 {
  height: 41.6px;
  position: relative;
  width: 26px;
}

.ecovest-onboarding .text-wrapper-3 {
  color: var(--graymain-900);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .frame-wrapper {
  background-color: var(--graymain-10);
  border-radius: 4px;
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 118px;
}

.ecovest-onboarding .frame-6 {
  background-color: var(--ecogreen-400);
  border-radius: 4px;
  height: 4px;
  width: 72px;
}

.ecovest-onboarding .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.ecovest-onboarding .p {
  color: var(--ecogreen-1000);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 44px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48.4px;
  margin-top: -1px;
  position: relative;
  width: 745px;
}

.ecovest-onboarding .earning-the-right-to {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 745px;
}

.ecovest-onboarding .span {
  color: #16260d;
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}

.ecovest-onboarding .text-wrapper-4 {
  font-weight: 700;
}

.ecovest-onboarding .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.ecovest-onboarding .frame-9 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(115.14, 116.22, 120, 0), rgb(213, 215, 222)) 1;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 32px 0px;
  position: relative;
}

.ecovest-onboarding .text-wrapper-5 {
  color: var(--ecogreen-600);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 57.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .text-wrapper-6 {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 523px;
}

.ecovest-onboarding .as-you-interact-with {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 530px;
}

.ecovest-onboarding .text-wrapper-7 {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 491px;
}

.ecovest-onboarding .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 32px 0px;
  position: relative;
}

.ecovest-onboarding .text-wrapper-8 {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 636px;
}

.ecovest-onboarding .frame-11 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 261px;
  position: absolute;
  top: 1160px;
}

.ecovest-onboarding .frame-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.ecovest-onboarding .logo-mark {
  height: 143.46px;
  position: relative;
  width: 142.88px;
}

.ecovest-onboarding .text-wrapper-9 {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 84px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 67.2px;
  position: relative;
  text-align: center;
  width: 918px;
}

.ecovest-onboarding .text-wrapper-10 {
  color: var(--ecogreen-800);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 172px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 137.6px;
  position: relative;
  text-align: center;
  width: 918px;
}

.ecovest-onboarding .text-wrapper-11 {
  color: var(--ecogreen-1000);
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  text-align: center;
  width: 915px;
}

.ecovest-onboarding .btn-ecovest-instance {
  flex: 0 0 auto !important;
}

.ecovest-onboarding .image-4 {
  height: 725px;
  left: 0;
  position: absolute;
  top: 1646px;
  width: 535px;
}

.ecovest-onboarding .design-component-instance-node {
  left: 85px !important;
  position: absolute !important;
  top: 460px !important;
}

.ecovest-onboarding .footer {
  background-color: #406625;
  height: 306px;
  left: 0;
  position: absolute;
  top: 5865px;
  width: 1440px;
}

.ecovest-onboarding .logo-instance {
  height: 39px !important;
  left: 85px !important;
  position: absolute !important;
  top: 52px !important;
  width: 168px !important;
}

.ecovest-onboarding .text-wrapper-12 {
  color: #ffffff;
  font-family: var(--body-large-font-family);
  font-size: var(--body-large-font-size);
  font-style: var(--body-large-font-style);
  font-weight: var(--body-large-font-weight);
  left: 85px;
  letter-spacing: var(--body-large-letter-spacing);
  line-height: var(--body-large-line-height);
  position: absolute;
  top: 106px;
  width: 288px;
}

.ecovest-onboarding .line {
  height: 1px;
  left: 80px;
  object-fit: cover;
  position: absolute;
  top: 256px;
  width: 1281px;
}

.ecovest-onboarding .text-wrapper-13 {
  color: #ffffff;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  left: 85px;
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.ecovest-onboarding .condition-of-use {
  color: #ffffff;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  left: 1083px;
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  position: absolute;
  top: 272px;
  white-space: nowrap;
}

.ecovest-onboarding .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 1082px;
  position: absolute;
  top: 54px;
}

.ecovest-onboarding .text-wrapper-14 {
  color: #ffffff;
  font-family: var(--headline-medium-font-family);
  font-size: var(--headline-medium-font-size);
  font-style: var(--headline-medium-font-style);
  font-weight: var(--headline-medium-font-weight);
  letter-spacing: var(--headline-medium-letter-spacing);
  line-height: var(--headline-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .text-wrapper-15 {
  color: #ffffff;
  font-family: var(--body-large-font-family);
  font-size: var(--body-large-font-size);
  font-style: var(--body-large-font-style);
  font-weight: var(--body-large-font-weight);
  letter-spacing: var(--body-large-letter-spacing);
  line-height: var(--body-large-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .social-media {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.ecovest-onboarding .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.ecovest-onboarding .overlap-group-wrapper {
  height: 1288px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 4577px;
  width: 1440px;
}

.ecovest-onboarding .div-wrapper {
  background: linear-gradient(180deg, rgb(158, 207, 54) 0%, rgb(101.28, 138.45, 22.4) 100%);
  height: 1553px;
  width: 1441px;
}

.ecovest-onboarding .frame-14 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  left: 111px;
  position: relative;
  top: 112px;
}

.ecovest-onboarding .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  position: relative;
}

.ecovest-onboarding .logo-2 {
  height: 57px;
  position: relative;
  width: 246px;
}

.ecovest-onboarding .frame-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.ecovest-onboarding .text-wrapper-16 {
  color: var(--ecogreen-700);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 84px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 84px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 1219px;
}

.ecovest-onboarding .in-steps {
  color: var(--ecogreen-50);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 60px;
  position: relative;
  text-align: center;
  width: 1219px;
}

.ecovest-onboarding .hero-section {
  background-color: #ffffff;
  height: 811px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.ecovest-onboarding .overlap-3 {
  height: 1054px;
  left: -1px;
  position: relative;
  width: 1528px;
}

.ecovest-onboarding .element-tiny {
  background-color: #000000;
  height: 811px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1441px;
}

.ecovest-onboarding .rectangle-4 {
  background: radial-gradient(150% 50% at 50% 45%, rgba(107.97, 163.36, 74.25, 0) 0%, rgb(40.55, 61.36, 27.89) 100%);
  height: 811px;
  position: absolute;
  top: 0;
  width: 1439px;
}

.ecovest-onboarding .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  left: 86px;
  position: absolute;
  top: 254px;
}

.ecovest-onboarding .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.ecovest-onboarding .text-wrapper-17 {
  color: #ffffff;
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 84px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 84px;
  margin-top: -1px;
  position: relative;
  width: 1026px;
}

.ecovest-onboarding .text-wrapper-18 {
  color: #ffffff;
  font-family: "Kufam", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 953px;
}

.ecovest-onboarding .btn-ecovest-wrapper {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 1214px;
}

.ecovest-onboarding .logo-3 {
  height: 61px;
  left: 86px;
  position: absolute;
  top: 64px;
  width: 264px;
}

.ecovest-onboarding .ellipse-13 {
  background-color: #9ecf36;
  border-radius: 424.5px;
  filter: blur(220px);
  height: 849px;
  left: 679px;
  mix-blend-mode: soft-light;
  position: absolute;
  top: 205px;
  width: 849px;
}

.ecovest-onboarding .number-trees-planted {
  height: 310px;
  left: 1015px;
  position: absolute;
  top: 574px;
  width: 300px;
}

.ecovest-onboarding .overlap-group-3 {
  height: 310px;
  position: relative;
}

.ecovest-onboarding .ellipse-14 {
  border: 1px solid;
  border-color: #9ecf36;
  border-radius: 150px;
  height: 300px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 300px;
}

.ecovest-onboarding .pikpng {
  height: 106px;
  left: 88px;
  position: absolute;
  top: 21px;
  width: 68px;
}

.ecovest-onboarding .frame-19 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 60px;
  position: absolute;
  top: 76px;
}

.ecovest-onboarding .text-wrapper-19 {
  color: #9ecf36;
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 104px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 104px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ecovest-onboarding .planted-trees-until {
  color: #ffffff;
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: fit-content;
}

.ecovest-onboarding .pikpng-2 {
  height: 145px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 93px;
}

.onboardingcontainer{
  background: linear-gradient(180deg, rgb(40.55, 61.36, 27.89) 0%, rgb(158, 207, 54) 90%, rgb(255, 255, 255) 100%);
}

.ecovest-onboarding .logo-footer {
  left: 86px;
  position: absolute;
  top: 64px;
  width: 168px;
}

/* Add these media queries to your style.css file */

/* Large desktops (1200px and up) */
@media (max-width: 1440px) {
  .ecovest-onboarding {
    width: 100%;
    height: auto;
  }
  
  .overlap-wrapper,
  .overlap,
  .overlap-2 {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .rectangle,
  .rectangle-2,
  .rectangle-3 {
    width: 100%;
  }
  
  .group {
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: auto;
  }
  
  .frame-2 {
    left: 5%;
    width: 90%;
  }
  
  .playful-engaging {
    font-size: 10vw;
    line-height: 1;
    width: 100%;
  }
  
  .text-wrapper {
    width: 100%;
    font-size: 1.8vw;
  }
}

/* Tablets (768px to 1199px) */
@media (max-width: 1199px) {
  .playful-engaging {
    font-size: 12vw;
  }
  
  .text-wrapper {
    font-size: 2.2vw;
  }
  
  .frame-11 {
    left: 5% !important;
    width: 90% !important;
  }
  
  .text-wrapper-9,
  .text-wrapper-10,
  .text-wrapper-11 {
    width: 100% !important;
  }
  
  .text-wrapper-10 {
    font-size: 12vw;
    line-height: 1;
  }
  
  .hero-section {
    height: auto !important;
  }
  
  .overlap-3 {
    height: auto !important;
  }
  
  .element-tiny video {
    width: 100%;
    height: auto;
  }
}

/* Small tablets and large phones (576px to 767px) */
@media (max-width: 767px) {
  .playful-engaging {
    font-size: 14vw;
  }
  
  .text-wrapper {
    font-size: 3vw;
  }
  
  .frame-3 {
    left: 5% !important;
    width: 90% !important;
  }
  
  .p, .earning-the-right-to {
    width: 100% !important;
  }
  
  .footer {
    height: auto !important;
    padding: 2rem 0;
  }
  
  .text-wrapper-12,
  .text-wrapper-13,
  .condition-of-use {
    position: relative !important;
    left: auto !important;
    width: 100% !important;
    text-align: center;
    margin: 1rem 0;
  }
  
  .frame-13 {
    position: relative !important;
    left: auto !important;
    width: 100% !important;
    align-items: center !important;
    margin-top: 2rem;
  }
}

/* Small phones (up to 575px) */
@media (max-width: 575px) {
  .playful-engaging {
    font-size: 16vw;
    white-space: normal;
  }
  
  .text-wrapper {
    font-size: 4vw;
  }
  
  .container-wrapper {
    width: 100% !important;
    border-radius: 48px !important;
  }
  
  .text-wrapper-17 {
    font-size: 10vw !important;
    line-height: 1.2 !important;
    width: 100% !important;
  }
  
  .text-wrapper-18 {
    font-size: 4vw !important;
    width: 100% !important;
  }
  
  .number-trees-planted {
    left: 50% !important;
    transform: translateX(-50%);
    width: 80% !important;
  }
  
  .text-wrapper-19 {
    font-size: 16vw !important;
  }
  
  .planted-trees-until {
    font-size: 5vw !important;
  }
}

/* Additional component-specific responsiveness */
@media (max-width: 991px) {
  .image,
  .image-2,
  .green-beautiful-tree,
  .image-4 {
    position: relative !important;
    left: 50% !important;
    transform: translateX(-50%);
    width: 80% !important;
    height: auto !important;
    margin: 1rem 0;
  }
  
  .img-2 {
    width: 100% !important;
    height: auto !important;
    left: 0 !important;
  }
}

@media (max-width: 767px) {
  .frame-4 {
    flex-direction: column !important;
    align-items: center !important;
  }
  
  .frame-8 {
    padding: 0 1rem !important;
  }
  
  .text-wrapper-5 {
    font-size: 6vw !important;
  }
  
  .text-wrapper-6,
  .as-you-interact-with,
  .text-wrapper-7,
  .text-wrapper-8 {
    width: 100% !important;
    font-size: 4vw !important;
  }
}