.carouselonboarding {
  height: 600px;
  position: relative;
  width: 1207px;
}

.carouselonboarding .arrow-right {
  height: 56px;
  left: 1151px;
  position: absolute;
  top: 287px;
  width: 56px;
}

.carouselonboarding .img {
  height: 56px;
  left: 0;
  position: absolute;
  top: 287px;
  width: 56px;
}

.carouselonboarding .overlap-group {
  height: 601px;
  left: 124px;
  position: absolute;
  top: -1px;
  width: 983px;
}

.carouselonboarding .element {
  color: var(--ecogreen-600);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 600px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 600px;
  opacity: 0.16;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.carouselonboarding .frame {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 0;
  position: absolute;
  top: 209px;
}

.carouselonboarding .join-the-tannit {
  color: var(--ecogreen-1000);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 44px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.carouselonboarding .sign-up-for-a-free {
  color: var(--ecogreen-900);
  font-family: "Kufam", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  position: relative;
  text-align: center;
  width: 983px;
}

.carouselonboarding .div {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 435px;
  position: absolute;
  top: 585px;
}

.carouselonboarding .ellipse {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-2 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-3 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-4 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .variant-4 {
  left: 187px;
}

.carouselonboarding .default {
  left: 268px;
}

.carouselonboarding .variant-3 {
  left: 206px;
}

.carouselonboarding .variant-2 {
  left: 201px;
}

.carouselonboarding .property-1-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-default {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-variant-2 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-2 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-1-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-1-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-1-variant-3 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-1-variant-2 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-4 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-2-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-2 {
  background-color: var(--ecogreen-400);
}

/* Responsive Media Queries */
@media (max-width: 1400px) {
  .carouselonboarding {
    width: 100%;
    max-width: 1207px;
  }
  
  .carouselonboarding .overlap-group {
    width: 80%;
    left: 10%;
  }
  
  .carouselonboarding .arrow-right {
    left: calc(100% - 66px);
  }
  
  .carouselonboarding .sign-up-for-a-free {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .carouselonboarding {
    height: 500px;
  }
  
  .carouselonboarding .element {
    font-size: 500px;
    line-height: 500px;
  }
  
  .carouselonboarding .frame {
    top: 170px;
  }
  
  .carouselonboarding .join-the-tannit {
    font-size: 38px;
    line-height: 38px;
  }
  
  .carouselonboarding .sign-up-for-a-free {
    font-size: 24px;
    line-height: 38px;
  }
  
  .carouselonboarding .div {
    top: 480px;
  }
}

@media (max-width: 992px) {
  .carouselonboarding {
    height: 400px;
  }
  
  .carouselonboarding .element {
    font-size: 400px;
    line-height: 400px;
  }
  
  .carouselonboarding .frame {
    top: 140px;
  }
  
  .carouselonboarding .join-the-tannit {
    font-size: 32px;
    line-height: 32px;
  }
  
  .carouselonboarding .sign-up-for-a-free {
    font-size: 20px;
    line-height: 32px;
  }
  
  .carouselonboarding .div {
    top: 380px;
  }
  
  .carouselonboarding .arrow-right,
  .carouselonboarding .img {
    height: 48px;
    width: 48px;
    top: 180px;
  }
}

@media (max-width: 768px) {
  .carouselonboarding {
    height: 350px;
  }
  
  .carouselonboarding .element {
    font-size: 300px;
    line-height: 300px;
  }
  
  .carouselonboarding .frame {
    top: 120px;
  }
  
  .carouselonboarding .join-the-tannit {
    font-size: 28px;
    line-height: 28px;
    white-space: normal;
  }
  
  .carouselonboarding .sign-up-for-a-free {
    font-size: 18px;
    line-height: 28px;
  }
  
  .carouselonboarding .div {
    top: 320px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carouselonboarding .arrow-right,
  .carouselonboarding .img {
    height: 40px;
    width: 40px;
    top: 160px;
  }
}

@media (max-width: 576px) {
  .carouselonboarding {
    height: 300px;
  }
  
  .carouselonboarding .element {
    font-size: 200px;
    line-height: 200px;
  }
  
  .carouselonboarding .frame {
    top: 100px;
    gap: 16px;
  }
  
  .carouselonboarding .join-the-tannit {
    font-size: 24px;
    line-height: 24px;
  }
  
  .carouselonboarding .sign-up-for-a-free {
    font-size: 16px;
    line-height: 24px;
    padding: 0 20px;
  }
  
  .carouselonboarding .div {
    top: 280px;
  }
  
  .carouselonboarding .arrow-right {
    left: calc(100% - 50px);
  }
  
  .carouselonboarding .arrow-right,
  .carouselonboarding .img {
    height: 32px;
    width: 32px;
    top: 140px;
  }
  
  .carouselonboarding .ellipse,
  .carouselonboarding .ellipse-2,
  .carouselonboarding .ellipse-3,
  .carouselonboarding .ellipse-4 {
    height: 12px;
    width: 12px;
  }
}

@media (max-width: 400px) {
  .carouselonboarding {
    height: 250px;
  }
  
  .carouselonboarding .element {
    font-size: 150px;
    line-height: 150px;
  }
  
  .carouselonboarding .frame {
    top: 80px;
  }
  
  .carouselonboarding .join-the-tannit {
    font-size: 20px;
    line-height: 20px;
  }
  
  .carouselonboarding .sign-up-for-a-free {
    font-size: 14px;
    line-height: 20px;
  }
  
  .carouselonboarding .div {
    top: 230px;
  }
  
  .carouselonboarding .arrow-right,
  .carouselonboarding .img {
    height: 28px;
    width: 28px;
    top: 120px;
  }
}